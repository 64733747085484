import { setReferral } from "@/api/auth.service"
const COOKIE_KEY = 'referral'

export default function ({ app, route, $sentry, $segment,  $cookies, $config }) {
  try {
    const urlReferral = route.query?.r
    if (urlReferral) {
      $segment.identify({write_referral: urlReferral})
      if (!app.$auth.loggedIn) {
        $cookies.set(COOKIE_KEY, urlReferral, { domain: "." + $config.url })
      } else {
        app.$api.run(setReferral, urlReferral)
      }
      return
    }

    const cookieReferral = $cookies.get(COOKIE_KEY)
    if (cookieReferral && app.$auth.loggedIn) {
      $cookies.remove(COOKIE_KEY, { domain: "." + $config.url })
      app.$api.run(setReferral, cookieReferral)
    }
  } catch (err) {
    $sentry.captureException(err)
  }
}
