import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0b29cdcf = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _0503bb1b = () => interopDefault(import('../pages/agb.vue' /* webpackChunkName: "pages/agb" */))
const _9059de54 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _43279d0a = () => interopDefault(import('../pages/checkout_success.vue' /* webpackChunkName: "pages/checkout_success" */))
const _6b51ce82 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _d37a0e7c = () => interopDefault(import('../pages/datenschutz.vue' /* webpackChunkName: "pages/datenschutz" */))
const _13f04a31 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _ffc1c1a4 = () => interopDefault(import('../pages/features.vue' /* webpackChunkName: "pages/features" */))
const _28941638 = () => interopDefault(import('../pages/flows.vue' /* webpackChunkName: "pages/flows" */))
const _6ca0dbd6 = () => interopDefault(import('../pages/impressum.vue' /* webpackChunkName: "pages/impressum" */))
const _44d02570 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _457c4a9c = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _78b10fb0 = () => interopDefault(import('../pages/pricing/index.vue' /* webpackChunkName: "pages/pricing/index" */))
const _6fae4b4d = () => interopDefault(import('../pages/redirect.vue' /* webpackChunkName: "pages/redirect" */))
const _7831f752 = () => interopDefault(import('../pages/sources.vue' /* webpackChunkName: "pages/sources" */))
const _5446463a = () => interopDefault(import('../pages/blog/_article.vue' /* webpackChunkName: "pages/blog/_article" */))
const _90a97238 = () => interopDefault(import('../pages/feature/_feature/index.vue' /* webpackChunkName: "pages/feature/_feature/index" */))
const _4151dbe0 = () => interopDefault(import('../pages/flow/_flow/index.vue' /* webpackChunkName: "pages/flow/_flow/index" */))
const _7a558ebe = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _0b29cdcf,
    name: "account___de"
  }, {
    path: "/agb",
    component: _0503bb1b,
    name: "agb___de"
  }, {
    path: "/blog",
    component: _9059de54,
    name: "blog___de"
  }, {
    path: "/checkout_success",
    component: _43279d0a,
    name: "checkout_success___de"
  }, {
    path: "/contact",
    component: _6b51ce82,
    name: "contact___de"
  }, {
    path: "/datenschutz",
    component: _d37a0e7c,
    name: "datenschutz___de"
  }, {
    path: "/en",
    component: _13f04a31,
    name: "index___en"
  }, {
    path: "/es",
    component: _13f04a31,
    name: "index___es"
  }, {
    path: "/features",
    component: _ffc1c1a4,
    name: "features___de"
  }, {
    path: "/flows",
    component: _28941638,
    name: "flows___de"
  }, {
    path: "/fr",
    component: _13f04a31,
    name: "index___fr"
  }, {
    path: "/impressum",
    component: _6ca0dbd6,
    name: "impressum___de"
  }, {
    path: "/it",
    component: _13f04a31,
    name: "index___it"
  }, {
    path: "/login",
    component: _44d02570,
    name: "login___de"
  }, {
    path: "/maintenance",
    component: _457c4a9c,
    name: "maintenance___de"
  }, {
    path: "/nl",
    component: _13f04a31,
    name: "index___nl"
  }, {
    path: "/pricing",
    component: _78b10fb0,
    name: "pricing___de"
  }, {
    path: "/redirect",
    component: _6fae4b4d,
    name: "redirect___de"
  }, {
    path: "/sources",
    component: _7831f752,
    name: "sources___de"
  }, {
    path: "/en/account",
    component: _0b29cdcf,
    name: "account___en"
  }, {
    path: "/en/agb",
    component: _0503bb1b,
    name: "agb___en"
  }, {
    path: "/en/blog",
    component: _9059de54,
    name: "blog___en"
  }, {
    path: "/en/checkout_success",
    component: _43279d0a,
    name: "checkout_success___en"
  }, {
    path: "/en/contact",
    component: _6b51ce82,
    name: "contact___en"
  }, {
    path: "/en/datenschutz",
    component: _d37a0e7c,
    name: "datenschutz___en"
  }, {
    path: "/en/features",
    component: _ffc1c1a4,
    name: "features___en"
  }, {
    path: "/en/flows",
    component: _28941638,
    name: "flows___en"
  }, {
    path: "/en/impressum",
    component: _6ca0dbd6,
    name: "impressum___en"
  }, {
    path: "/en/login",
    component: _44d02570,
    name: "login___en"
  }, {
    path: "/en/maintenance",
    component: _457c4a9c,
    name: "maintenance___en"
  }, {
    path: "/en/pricing",
    component: _78b10fb0,
    name: "pricing___en"
  }, {
    path: "/en/redirect",
    component: _6fae4b4d,
    name: "redirect___en"
  }, {
    path: "/en/sources",
    component: _7831f752,
    name: "sources___en"
  }, {
    path: "/es/account",
    component: _0b29cdcf,
    name: "account___es"
  }, {
    path: "/es/agb",
    component: _0503bb1b,
    name: "agb___es"
  }, {
    path: "/es/blog",
    component: _9059de54,
    name: "blog___es"
  }, {
    path: "/es/checkout_success",
    component: _43279d0a,
    name: "checkout_success___es"
  }, {
    path: "/es/contact",
    component: _6b51ce82,
    name: "contact___es"
  }, {
    path: "/es/datenschutz",
    component: _d37a0e7c,
    name: "datenschutz___es"
  }, {
    path: "/es/features",
    component: _ffc1c1a4,
    name: "features___es"
  }, {
    path: "/es/flows",
    component: _28941638,
    name: "flows___es"
  }, {
    path: "/es/impressum",
    component: _6ca0dbd6,
    name: "impressum___es"
  }, {
    path: "/es/login",
    component: _44d02570,
    name: "login___es"
  }, {
    path: "/es/maintenance",
    component: _457c4a9c,
    name: "maintenance___es"
  }, {
    path: "/es/pricing",
    component: _78b10fb0,
    name: "pricing___es"
  }, {
    path: "/es/redirect",
    component: _6fae4b4d,
    name: "redirect___es"
  }, {
    path: "/es/sources",
    component: _7831f752,
    name: "sources___es"
  }, {
    path: "/fr/account",
    component: _0b29cdcf,
    name: "account___fr"
  }, {
    path: "/fr/agb",
    component: _0503bb1b,
    name: "agb___fr"
  }, {
    path: "/fr/blog",
    component: _9059de54,
    name: "blog___fr"
  }, {
    path: "/fr/checkout_success",
    component: _43279d0a,
    name: "checkout_success___fr"
  }, {
    path: "/fr/contact",
    component: _6b51ce82,
    name: "contact___fr"
  }, {
    path: "/fr/datenschutz",
    component: _d37a0e7c,
    name: "datenschutz___fr"
  }, {
    path: "/fr/features",
    component: _ffc1c1a4,
    name: "features___fr"
  }, {
    path: "/fr/flows",
    component: _28941638,
    name: "flows___fr"
  }, {
    path: "/fr/impressum",
    component: _6ca0dbd6,
    name: "impressum___fr"
  }, {
    path: "/fr/login",
    component: _44d02570,
    name: "login___fr"
  }, {
    path: "/fr/maintenance",
    component: _457c4a9c,
    name: "maintenance___fr"
  }, {
    path: "/fr/pricing",
    component: _78b10fb0,
    name: "pricing___fr"
  }, {
    path: "/fr/redirect",
    component: _6fae4b4d,
    name: "redirect___fr"
  }, {
    path: "/fr/sources",
    component: _7831f752,
    name: "sources___fr"
  }, {
    path: "/it/account",
    component: _0b29cdcf,
    name: "account___it"
  }, {
    path: "/it/agb",
    component: _0503bb1b,
    name: "agb___it"
  }, {
    path: "/it/blog",
    component: _9059de54,
    name: "blog___it"
  }, {
    path: "/it/checkout_success",
    component: _43279d0a,
    name: "checkout_success___it"
  }, {
    path: "/it/contact",
    component: _6b51ce82,
    name: "contact___it"
  }, {
    path: "/it/datenschutz",
    component: _d37a0e7c,
    name: "datenschutz___it"
  }, {
    path: "/it/features",
    component: _ffc1c1a4,
    name: "features___it"
  }, {
    path: "/it/flows",
    component: _28941638,
    name: "flows___it"
  }, {
    path: "/it/impressum",
    component: _6ca0dbd6,
    name: "impressum___it"
  }, {
    path: "/it/login",
    component: _44d02570,
    name: "login___it"
  }, {
    path: "/it/maintenance",
    component: _457c4a9c,
    name: "maintenance___it"
  }, {
    path: "/it/pricing",
    component: _78b10fb0,
    name: "pricing___it"
  }, {
    path: "/it/redirect",
    component: _6fae4b4d,
    name: "redirect___it"
  }, {
    path: "/it/sources",
    component: _7831f752,
    name: "sources___it"
  }, {
    path: "/nl/account",
    component: _0b29cdcf,
    name: "account___nl"
  }, {
    path: "/nl/agb",
    component: _0503bb1b,
    name: "agb___nl"
  }, {
    path: "/nl/blog",
    component: _9059de54,
    name: "blog___nl"
  }, {
    path: "/nl/checkout_success",
    component: _43279d0a,
    name: "checkout_success___nl"
  }, {
    path: "/nl/contact",
    component: _6b51ce82,
    name: "contact___nl"
  }, {
    path: "/nl/datenschutz",
    component: _d37a0e7c,
    name: "datenschutz___nl"
  }, {
    path: "/nl/features",
    component: _ffc1c1a4,
    name: "features___nl"
  }, {
    path: "/nl/flows",
    component: _28941638,
    name: "flows___nl"
  }, {
    path: "/nl/impressum",
    component: _6ca0dbd6,
    name: "impressum___nl"
  }, {
    path: "/nl/login",
    component: _44d02570,
    name: "login___nl"
  }, {
    path: "/nl/maintenance",
    component: _457c4a9c,
    name: "maintenance___nl"
  }, {
    path: "/nl/pricing",
    component: _78b10fb0,
    name: "pricing___nl"
  }, {
    path: "/nl/redirect",
    component: _6fae4b4d,
    name: "redirect___nl"
  }, {
    path: "/nl/sources",
    component: _7831f752,
    name: "sources___nl"
  }, {
    path: "/",
    component: _13f04a31,
    name: "index___de"
  }, {
    path: "/en/blog/:article",
    component: _5446463a,
    name: "blog-article___en"
  }, {
    path: "/en/feature/:feature",
    component: _90a97238,
    name: "feature-feature___en"
  }, {
    path: "/en/flow/:flow",
    component: _4151dbe0,
    name: "flow-flow___en"
  }, {
    path: "/es/blog/:article",
    component: _5446463a,
    name: "blog-article___es"
  }, {
    path: "/es/feature/:feature",
    component: _90a97238,
    name: "feature-feature___es"
  }, {
    path: "/es/flow/:flow",
    component: _4151dbe0,
    name: "flow-flow___es"
  }, {
    path: "/fr/blog/:article",
    component: _5446463a,
    name: "blog-article___fr"
  }, {
    path: "/fr/feature/:feature",
    component: _90a97238,
    name: "feature-feature___fr"
  }, {
    path: "/fr/flow/:flow",
    component: _4151dbe0,
    name: "flow-flow___fr"
  }, {
    path: "/it/blog/:article",
    component: _5446463a,
    name: "blog-article___it"
  }, {
    path: "/it/feature/:feature",
    component: _90a97238,
    name: "feature-feature___it"
  }, {
    path: "/it/flow/:flow",
    component: _4151dbe0,
    name: "flow-flow___it"
  }, {
    path: "/nl/blog/:article",
    component: _5446463a,
    name: "blog-article___nl"
  }, {
    path: "/nl/feature/:feature",
    component: _90a97238,
    name: "feature-feature___nl"
  }, {
    path: "/nl/flow/:flow",
    component: _4151dbe0,
    name: "flow-flow___nl"
  }, {
    path: "/blog/:article",
    component: _5446463a,
    name: "blog-article___de"
  }, {
    path: "/feature/:feature",
    component: _90a97238,
    name: "feature-feature___de"
  }, {
    path: "/flow/:flow",
    component: _4151dbe0,
    name: "flow-flow___de"
  }, {
    path: "/nl/*",
    component: _7a558ebe,
    name: "all___nl"
  }, {
    path: "/it/*",
    component: _7a558ebe,
    name: "all___it"
  }, {
    path: "/es/*",
    component: _7a558ebe,
    name: "all___es"
  }, {
    path: "/fr/*",
    component: _7a558ebe,
    name: "all___fr"
  }, {
    path: "/en/*",
    component: _7a558ebe,
    name: "all___en"
  }, {
    path: "/*",
    component: _7a558ebe,
    name: "all___de"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
