import Vue from 'vue'
import CookieConsentClass from '@/components/user/CookieConsent'

const COOKIE_NAME = 'consent'
const HIDE_ON_PAGES = [
  'redirect',
  'checkout_success',
  'checkout_canceled',
  'login',
  'logout',
]
const cookies = Vue.observable({
  fullstory: false,
  hotjar: false,
  gtag: false,
  required: true,
})

const settings = Vue.observable({
  show: true,
  isSet: false,
})

export default ({ app, $cookies }, inject) => {
  const raw = $cookies.get(COOKIE_NAME)
  settings.isSet = !!raw
  Object.assign(cookies, raw)
  app.mixins.push({
    beforeCreate() {
      if (this.$auth.loggedIn) {
        /** Allow all features, since user is loggedIn */
        Object.keys(cookies).forEach((key) => {
          cookies[key] = true
        })
      }
    },
  })
  if (!settings.isSet) {
    app.mixins.push({
      mounted() {
        if (HIDE_ON_PAGES.some((route) => this.$route.name?.includes(route)))
          return
        if (this.$auth.loggedIn) return
        const CookieConsent = Vue.component('CookieConsent', CookieConsentClass)
        const Component = new CookieConsent({ parent: this })
        const ele = document.getElementById('app')
        const node = document.createElement('div')
        node.setAttribute('id', 'cookieConsent')
        if (ele) {
          ele.appendChild(node)
          Component.$mount('#cookieConsent')
        }
      },
    })
  }
  function setCookies() {
    settings.isSet = true
    $cookies.set(COOKIE_NAME, cookies)
    settings.show = false
  }

  function consentAll() {
    Object.keys(cookies).forEach((key) => {
      cookies[key] = true
    })
    setCookies()
  }

  function consentRequired() {
    Object.keys(cookies).forEach((key) => {
      cookies[key] = false
    })
    cookies.required = true
    setCookies()
  }
  function setConsent(object) {
    Object.assign(cookies, object)
    setCookies()
  }

  inject('consent', {
    cookies,
    settings,
    consentAll,
    setConsent,
    consentRequired,
  })
}
