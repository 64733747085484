export default {
  code: "it",
  flag: "🇮🇹",
  meta: {
    index: {
      title: 'Tesine migliori.\n' +
        '10 volte più veloci con l\'IA.',
      description: 'Senza Hermann: Stai fissando una pagina vuota per ore.\n' +
        'Con Hermann: Generi ottimi testi con un semplice clic.'
    }
  },
  validations: {
    required: "{_field_} è un campo obbligatorio",
    min: "{_field_} deve essere lungo almeno {length} caratteri",
    max: "{_field_} non deve superare i {length} caratteri",
  },
  buttons: {
    close: "Chiudi",
    upgrade: "Aggiorna",
    get_more_words: "Ottieni più parole ora",
    login: "Accedi",
    logout: "Esci",
    cancel: "Annulla",
    yes: "Sì",
    no: "No",
    tryout: "Provalo",
    add: "Aggiungi",
    report: "Segnala",
    save: "Salva",
    edit: "Modifica",
    copy: "Copia",
    delete: "Elimina",
    generate: "Genera",
    signup: "Registrati",
    continue: "Continua",
    submit: "Invia",
    add_source: "Aggiungi Fonte",
    select_source: "Seleziona Fonte",
    new_document: "Nuovo Documento",
    back: "indietro",
    local_document: {
      title: "Un documento dal mio computer",
      type: "Carica Documento"
    },
    web_document: {
      title: "Hai un link a un documento?",
      type: "Web Link/Documento"
    },
  },
  labels: {
    missing_subscription: "Hai bisogno di un abbonamento per utilizzare questo strumento.",
    booked: "Prenotato",
    account: "Account",
    subscription: "Abbonamento",
    name: "Nome",
    email: "Email",
    limit: "Limite",
    settings: "Impostazioni",
    words: "Parole",
    usage: "Utilizzo",
    extra_words: "incl. {words} parole extra",
    show_all_applications: "Mostra tutte le applicazioni",
    show_all: "Mostra tutto",
    start_page: "Pagina iniziale",
    is_favorite: "Nei preferiti",
    add_favorite: "Aggiungi ai preferiti",
    wordlimit_reached: "Limite di parole raggiunto",
    wordlimit_increase: "Aumenta il limite di parole",
    contact: "Contatta",
    history: "Cronologia",
    show_history: "Mostra cronologia",
    inprint: "Impronta",
    dataregulation: "Regolamento dati",
    tos: "Condizioni di utilizzo",
    month: "Mese",
    cancel_anytime: "Annulla in qualsiasi momento",
    try_now: "Prova gratuita",
    book_again: "Prenota di nuovo",
    book: "Prenota ora",
    upgrade_to: "Aggiornamento a {product}",
    copied_link: "Link copiato",
    subject: "Oggetto",
    generated_by_hermann: "Generato da Hermann in 3 secondi.",
    use_template: "Usa modello",
    show_mode: "Mostra altro",
    upload: "Carica",
    optional: "opzionale",
    my_documents: "I Miei Documenti",
    my_sources: "Le Mie Fonti",
    add_source: "Aggiungi Fonte",
    select_source: "Seleziona Fonte",
    page: "Pagina {page}",
    unknown_author: "Autore sconosciuto",
    checker_results: "Risultati del controllo IA",
    human: "umano",
    citation_style: "Stile di citazione",
    started_at: "iniziato il",
    ends_at: "finisce il",
    cancel_subscription: "annulla abbonamento"
  },
  blog: {
    written_by: "Scritto da",
    read_article: "Leggi articolo",
  },
  source: {
    select: {
      empty: {
        headline: "Qui non c'è ancora nulla.",
        hint: "Puoi contrassegnare le fonti in 'Trova Fonti' e usarle in vari strumenti."
      },
    },
    table: {
      name: "Nome",
      type: "Tipo",
      created_at: "Creato il",
    },
    type: {
      document: "Documento",
      article: "Articolo",
      paper: "Carta",
    },
    hint: {
      not_usable: "Le fonti non ancora elaborate non saranno considerate durante la scrittura.",
    },
    processing: "in elaborazione...",
    recent: "usato di recente",
    bookmark: "segnalibro",
    bookmarked: "segna come preferito"
  },
  placeholders: {
    url: "https://en.wikipedia.org/wiki/Radiocarbon_dating"
  },
  groups: {
    research: "Ricerca",
    essay: "Essay",
    misc_tools: "Altri strumenti",
  },
  flows: {
    summarize: {
      headline: "Sommario",
      description: "Cosa vorresti riassumere?",
    },
    titles: {
      summarize_document: "Documento",
      summarize_text: "Testo",
      summarize_article: "Articolo",
      summarize_source: "Fonte",
    },
    prefill: {
      summarize_document: "Sommario del documento.",
    }
  },
  messages: {
    get_more_words: "Ottieni più parole ora",
    contact_success: "Grazie per il tuo messaggio!",
    error: "Qualcosa è andato storto. Per favore, riprova più tardi.",
  },
  validation: {
    required: "{_field_} è un campo obbligatorio",
    min: "{_field_} deve essere lungo almeno {length} caratteri",
    max: "{_field_} non può superare i {length} caratteri",
    maxarray: "{_field_} ha troppi elementi",
  },
  domains: {
    openai: {
      status: {
        unreliable: 'Al momento abbiamo un carico di server elevato. Potrebbero esserci interruzioni. Se qualcosa non funziona, riprova più tardi.',
        working_on_fix:'Stiamo attualmente lavorando a una soluzione. Riprova più tardi.',
      }
    },
    ai_checker: {
      hint: 'I detectori AI sono strumenti che controllano i testi per il contenuto AI. Con un clic su una delle carte, si accede allo strumento corrispondente.',
    },
    cookie: {
      text1: 'Hesse.ai utilizza i cookie e i dati per migliorare il nostro sito web e personalizzare i contenuti in base alle tue esigenze. Cliccando su "accetta", acconsenti all\'utilizzo dei cookie. Per ulteriori informazioni, consulta la nostra',
      user_behaviour_text: "Questi cookie ci consentono di analizzare il comportamento degli utenti su Hesse.ai in modo anonimo al fine di ottimizzare l'esperienza dell'utente.",
      user_behaviour: "Comportamento utente (hotjar)",
      user_behaviour2_text: "Questi cookie ci consentono di analizzare in modo anonimo la navigazione e l'origine degli utenti su Hesse.ai.",
      user_behaviour2: "Comportamento utente (Google Analytics)",
      technical: "Cookie tecnici necessari",
      technical_text: "Questi cookie sono necessari per garantire un utilizzo sicuro e affidabile.",
      consent: "Accetta",
      save: "Salva selezione",
      decline: "Rifiuta",
    },
    write: {
      confirm_delete_paragraph: "Vuoi eliminare davvero il paragrafo?",
      save_changes: "Salva modifiche",
      keep_writing: "Continua a scrivere",
    },
    report: {
      title: "Vuoi segnalare veramente questa risposta?",
      reason: "Motivo della segnalazione",
      description: "Segnala i risultati che contengono contenuti critici o illegali.",
      result_good: "Il risultato è utile",
      result_bad: "Il risultato non è utile",
    },
    pricing: {
      monthly_wordlimit: "{limit} parole al mese",
      increase_limit: "Aumenta il limite",
      wordlimit_reached: "Hai raggiunto il limite di parole",
      wordlimit_reached_description: "La quota per questo mese è esaurita. Se desideri continuare, devi aumentare il limite.",
      wordlimit_nearly_reached: "Le tue parole stanno per esaurirsi.",
      get_more_words: "Ottieni più parole",
      get_more_words_description: "I buoni testi richiedono molta potenza di calcolo. Questo è costoso.<br /> Ma abbiamo reso i nostri pacchetti il più economici possibile.",
      error_title: "La prenotazione non è riuscita.",
      error_description: "Contattaci: ti aiuteremo immediatamente.",
      text1: "Quante sono 10.000 parole?",
      text2: "Erano 10.000 parole",
      referral_title: "Non riesci ancora a deciderti?",
      referral_title2: "Hai bisogno di più parole?",
      referral_description: "Condividi il tuo link con gli amici. Ricevi 1.000 parole gratuite per ogni nuovo utente registrato tramite il tuo link.",
    },
    survey: {
      title: "Come valuti Hesse.ai?",
      suggestions: "Cosa cambieresti?",
      success_title: "Grazie mille per il tuo feedback!",
      success_text: "Ci stai aiutando a migliorare continuamente Hesse.ai",
      abort: "Non valutare",
    },
    cards_hint: {
      title: "Le schede sono disponibili ora",
      subtitle: "Creare automaticamente <strong>schede</strong> dalla tua <strong>lettura</strong>?",
      cta: "Crea schede ora",
    },
    share_modal: {
      headline: "Condividi Hesse - ottieni parole gratuite",
    },
    login_page: {
      loading: "Accesso in corso...",
      error: "Il login non è riuscito",
      delayed: "Il login sta richiedendo più tempo del solito.<br /> Controlla la tua connessione a internet e riprova se necessario.",
      try_again: "Accedi nuovamente",
    },
    index_page: {
      subtitle: 'Hermann Hesse ha detto una volta: "Un buon scrittore è colui che sa scrivere bene." Questo vale anche per il nostro programma. Possiamo aiutarti a portare i tuoi testi al livello successivo.',
      favourites: "I tuoi preferiti",
    },
    sidebar: {
      create_own_application: "Crea la tua applicazione",
      hesse_cards: "Hesse Cards 🃏",
      all_applications: "Tutte le applicazioni",
      favourites: "I miei preferiti",
    },
    profile: {
      title: "Impostazioni profilo",
      limit: "Limite",
      used_tokens: "Token utilizzati",
      get_more_words: "Ottieni più parole ora",
      no_subscription: "Non hai ancora un abbonamento.",
      upgrade_now: "Aggiorna ora",
      manage_subscription: "Gestisci abbonamento",
      delete_account: "Elimina account",
      deleted: "Il tuo account è stato eliminato. Se effettui l'accesso, verrà creato un nuovo account.",
    },
    contact: {
      title: "Contatto",
      subtitle: "Contattaci",
    },
    error_page: {
      title404: "404 - Pagina non trovata",
      title: "Si è verificato un errore",
      text: "Aiuta a migliorare Hesse.ai.<br /> Descrivi brevemente cosa hai fatto subito prima che apparisse questa pagina.",
      cta: "Torna alla pagina iniziale",
    },
    success_page: {
      loading: "Caricamento...",
      booking_success: "La tua prenotazione è andata a buon fine!",
      booked_product: "È stato prenotato {productName}",
      start_now: "Inizia ora",
    }
  },
  typewriter: {
    writeEssay: [
      // 'Ricerca nel database delle fonti...',
      // 'Ricerca di fonti con Google...',
      'Generazione di testi...',
      'Crittografia dei testi...',
      'Controllo del plagio...',
      'Esecuzione degli strumenti di controllo IA...',
      'Testi non più tracciabili ✔'
    ],
    writeOutline: [
      'Generazione di testi...',
      'Crittografia dei testi...',
      'Controllo del plagio...',
      'Esecuzione degli strumenti di controllo IA...',
      'Testi non più tracciabili ✔'
    ],
    writeIntroduction: [
      'Generazione di testi...',
      'Crittografia dei testi...',
      'Controllo del plagio...',
      'Esecuzione degli strumenti di controllo IA...',
      'Testi non più tracciabili ✔'
    ],
  }
};
