export default {
  code: "en",
  flag: "🇺🇸",
  meta: {
    index:{
      title: 'Better term papers.\n' +
             '10x faster with AI.',
      description: 'Without Hermann: You stare at an empty page for hours. \n' +
        'With Hermann: You generate great texts at the touch of a button.'
    }
  },
  validations: {
    required: "{_field_} is a required field",
    min: "{_field_} must be at least {length} characters long",
    max: "{_field_} must not be longer than {length} characters",
  },
  buttons: {
    close: "Close",
    upgrade: "upgrade",
    get_more_words: "Get more words now",
    login: "login",
    logout: "logout",
    cancel: "Cancel",
    yes: "Yes",
    no: "No",
    tryout: "try out",
    add: "add",
    report: "report",
    save: "save",
    edit: "edit",
    copy: "copy",
    delete: "delete",
    generate: "generate",
    signup: "sign up",
    continue: "continue",
    submit: "send",
    add_source: "Add Source",
    select_source: "Select Source",
    new_document: "New Document",
    back: "back",
    local_document: {
      title: "A Document from my Computer",
      type: "Upload Document"
    },
    web_document: {
      title: "You have a Link to a Document?",
      type: "Web Link/Document"
    },
  },
  labels: {
    missing_subscription: "You need a subscription to use this tool.",
    booked: "booked",
    account: "Account",
    subscription: "Subscription",
    name: "Name",
    email: "Email",
    limit: "Limit",
    settings: "Settings",
    words: "Words",
    usage: "Usage",
    extra_words: "including {words} extra words",
    show_all_applications: "Show all applications",
    show_all: "Show all",
    start_page: "Go to start page",
    is_favorite: "In favorites",
    add_favorite: "Add to favorites",
    wordlimit_reached: "Word limit reached",
    wordlimit_increase: "Increase word limit now",
    contact: "Open contact",
    history: "History",
    show_history: "show history",
    inprint: "Imprint",
    dataregulation: "Data regulation",
    tos: "Terms of Service",
    month: "Month",
    cancel_anytime: "Cancel anytime",
    try_now: "try now for free",
    book_again: "Book again",
    book: "Book now",
    upgrade_to: "Upgrade to {product}",
    copied_link: "Link copied",
    subject: "Subject",
    generated_by_hermann: "Generated by Hermann in 3 seconds.",
    use_template: "use template",
    show_mode: "show more",
    upload: "upload",
    optional: "optional",
    my_documents: "My Documents",
    my_sources: "My Sources",
    add_source: "Add Source",
    select_source: "Select Source",
    page: "Page {page}",
    unknown_author: "Unknown author",
    checker_results: "AI checker results",
    human: "human",
    citation_style: "Citation style",
    started_at: "started at",
    ends_at: "ends at",
    cancel_subscription: "cancel subscription"
  },
  blog: {
    written_by: "Written by",
    read_article: "Read article",
  },
  source: {
    select: {
      empty: {
        headline: "Nothing here yet.",
        hint: "You can mark sources in 'Find Sources' and use them in various tools."
      },
    },
    table: {
      name: "Name",
      type: "Type",
      created_at: "Created at",
    },
    type: {
      document: "Document",
      article: "Article",
      paper: "Paper",
    },
    hint: {
      not_usable: "Sources that have not yet been processed are not taken into account when writing.",
    },
    processing: "is being processed...",
    recent: "recently used",
    bookmark: "bookmark",
    bookmarked: "bookmarked"
  },
  placeholders: {
    url: "https://en.wikipedia.org/wiki/Radiocarbon_dating"
  },
  groups: {
    research: "Research",
    essay: "Essay",
    misc_tools: "Other Tools",
  },
  flows: {
    summarize: {
      headline: "Summarize",
      description: "What do you want to summarize?",
    },
    titles: {
      summarize_document: "Document",
      summarize_text: "Text",
      summarize_article: "Article",
      summarize_source: "Source",
    },
    prefill: {
      summarize_document: "Summarize the document.",
    }
  },
  messages: {
    get_more_words: "Get more words now",
    contact_success: "Thank you for your message!",
    error: "Something went wrong. Please try again later.",
  },
  validation: {
    required: "{_field_} is required",
    min: "{_field_} must be at least {length} characters long",
    max: "{_field_} must not be longer than {length} characters",
    maxarray: "{_field_} has too many elements",
  },
  domains: {
    openai: {
      status: {
        unreliable: 'We currently have increased server load. There may be outages. If something doesn\'t work, please try again later.',
        working_on_fix: 'There may currently be outages. We are already working on a solution. Please try again later.',
      }
    },
    ai_checker: {
      hint: 'AI checkers are tools that check texts for AI content. You can click on the cards to go to the corresponding tool.',
    },
    cookie: {
      text1: 'Hesse.ai uses cookies and data to improve our website and tailor the content to your needs. By confirming the "accept" button, you agree to their use. For more information, please refer to our',
      user_behaviour_text: "These cookies allow us to analyze user behavior on Hesse.ai in an anonymized way in order to optimize the user experience.",
      user_behaviour: "User behavior (hotjar)",
      user_behaviour2_text: "These cookies allow us to analyze the navigation and origin of users on Hesse.ai in an anonymized way.",
      user_behaviour2: "User behavior (Google Analytics)",
      technical: "Technically necessary cookies",
      technical_text: "These cookies are necessary to ensure a secure and reliable use.",
      consent: "Accept",
      save: "Apply selection",
      decline: "Decline",
    },
    write: {
      confirm_delete_paragraph: "Delete paragraph?",
      save_changes: "Save changes",
      keep_writing: "Keep writing",
    },
    report: {
      title: "Report this answer?",
      reason: "Reason for reporting",
      description: "Report results that contain critical or illegal content.",
      result_good: "Result is helpful",
      result_bad: "Result is not helpful",
    },
    pricing: {
      monthly_wordlimit: "{limit} words per month",
      increase_limit: "Increase limit",
      wordlimit_reached: "You have reached your word limit",
      wordlimit_reached_description: "The quota for this month has been exhausted. If you want to continue, you need to increase your limit.",
      wordlimit_nearly_reached: "Your words are nearly used up.",
      get_more_words: "Get more words",
      get_more_words_description: "High-quality texts require a lot of computing power. That's expensive.<br /> But we have made our packages as affordable as possible.",
      error_title: "The booking unfortunately did not work.",
      error_description: "Contact us - We will help immediately.",
      text1: "How much are 10,000 words?",
      text2: "That was 10,000 words",
      referral_title: "Can't decide yet?",
      referral_title2: "Need more words?",
      referral_description: "Share your link with friends. You will receive 1,000 free words for every new registration through your link.",
    },
    survey: {
      title: "How do you rate Hesse.ai?",
      suggestions: "What would you change?",
      success_title: "Thank you for your feedback!",
      success_text: "You help us continuously improve Hesse.ai",
      abort: "do not rate",
    },
    cards_hint: {
      title: "Flashcards are now available",
      subtitle: "Automatically <strong>create flashcards</strong> from your <strong>lecture</strong>?",
      cta: "Create flashcards now",
    },
    share_modal: {
      headline: "Share Hesse - get free words",
    },
    login_page: {
      loading: "Logging you in...",
      error: "Login was not successful",
      delayed: "The login is taking longer than usual.<br /> Please check your internet connection and try again if necessary.",
      try_again: "Log in again",
    },
    index_page: {
      subtitle: '"A good writer is someone who can write well," said Hermann Hesse. This also applies to our program. We can help you take your texts to the next level.',
      favourites: "Your favorites",
    },
    sidebar: {
      create_own_application: "Create your own application",
      hesse_cards: "Hesse Cards 🃏",
      all_applications: "All applications",
      favourites: "My favorites",
    },
    profile: {
      title: "Profile settings",
      limit: "Limit",
      used_tokens: "Used tokens",
      get_more_words: "Get more words now",
      no_subscription: "You don't have a subscription yet.",
      upgrade_now: "Upgrade now",
      manage_subscription: "Manage subscription",
      delete_account: "Delete account",
      deleted: "Your account has been deleted. If you log in, a new account will be created.",
    },
    contact: {
      title: "Contact",
      subtitle: "Contact us",
    },
    error_page: {
      title404: "404 - Page not found",
      title: "An error has occurred",
      text: "Help us improve Hesse.ai.<br /> Describe briefly what you did immediately before this page appeared.",
      cta: "Go to start page",
    },
    success_page: {
      loading: "loading...",
      booking_success: "Your booking was successful!",
      booked_product: "You have booked {productName}",
      start_now: "Get started now",
    }
  },
  typewriter: {
    writeEssay: [
    //   'Searching source database...',
    //   'Searching sources with Google...',
      'Generating texts...',
      'Encrypting texts...',
      'Plagiarism check...',
      'Running AI checker tools...',
      'Texts no longer traceable ✔'
    ],
    writeOutline: [
      'Generating texts...',
      'Encrypting texts...',
      'Plagiarism check...',
      'Running AI checker tools...',
      'Texts no longer traceable ✔'
    ],
    writeIntroduction: [
      'Generating texts...',
      'Encrypting texts...',
      'Plagiarism check...',
      'Running AI checker tools...',
      'Texts no longer traceable ✔'
    ],
  },
};
