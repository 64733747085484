
import { VSwitch } from 'vuetify/lib'

export default {
  name: 'CookieConsent',
  components: { VSwitch },
  data: () => ({
    consent: {
      fullstory: false,
      google: true,
      required: true,
    },
    showMore: false,
  }),
  created() {
    this.consent = { ...this.$consent.cookies }
  },
}
