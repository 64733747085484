import Vue from 'vue'

let isEnabled = false
export default ({ app, $consent, $config }, inject) => {
  if (!$config.isProduction) return
  function addScript() {
    if (isEnabled) return
    if (typeof window !== 'undefined') {
      isEnabled = true
      console.info('Enabling Hotjar')
      ;(function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            ;(h.hj.q = h.hj.q || []).push(arguments)
          }
        h._hjSettings = {
          hjid: '3236542',
          hjsv: '6',
        }
        a = o.getElementsByTagName('head')[0]
        r = o.createElement('script')
        r.async = 1
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
        a.appendChild(r)
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
    }
  }

  app.mixins.push({
    created() {
      if ($consent.cookies.hotjar) {
        addScript()
      }
    },
    watch: {
      '$consent.cookies.hotjar'(newVal) {
        if (newVal) {
          addScript()
        }
      },
    },
  })

  inject('hotjar', {
    enable: addScript,
    isEnabled: Vue.observable(isEnabled),
  })
}
