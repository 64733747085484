export default {
  code: "fr",
  flag: "🇫🇷",
  meta: {
    index: {
      title: 'De meilleurs travaux universitaires.\n' +
        '10 fois plus rapide avec l\'IA.',
      description: 'Sans Hermann : Vous regardez une page vide pendant des heures.\n' +
        'Avec Hermann : Vous générez d\'excellents textes en appuyant sur un bouton.'
    }
  },
  validations: {
    required: "{_field_} est un champ obligatoire",
    min: "{_field_} doit contenir au moins {length} caractères",
    max: "{_field_} ne doit pas dépasser {length} caractères",
  },
  buttons: {
    close: "Fermer",
    upgrade: "mise à niveau",
    get_more_words: "Obtenir plus de mots maintenant",
    login: "connexion",
    logout: "déconnexion",
    cancel: "Annuler",
    yes: "Oui",
    no: "Non",
    tryout: "essayer",
    add: "ajouter",
    report: "signaler",
    save: "enregistrer",
    edit: "modifier",
    copy: "copier",
    delete: "supprimer",
    generate: "générer",
    signup: "s'inscrire",
    continue: "continuer",
    submit: "envoyer",
    add_source: "Ajouter une source",
    select_source: "Sélectionner une source",
    new_document: "Nouveau Document",
    back: "retour",
    local_document: {
      title: "Un document de mon ordinateur",
      type: "Télécharger le document"
    },
    web_document: {
      title: "Avez-vous un lien vers un document?",
      type: "Lien Web/Document"
    },
  },
  labels: {
    missing_subscription: "Vous avez besoin d'un abonnement pour utiliser cet outil.",
    booked: "réservé",
    account: "Compte",
    subscription: "Abonnement",
    name: "Nom",
    email: "Adresse e-mail",
    limit: "Limite",
    settings: "Paramètres",
    words: "Mots",
    usage: "Utilisation",
    extra_words: "dont {words} mots supplémentaires",
    show_all_applications: "Afficher toutes les applications",
    show_all: "Tout afficher",
    start_page: "Page d'accueil",
    is_favorite: "Favori",
    add_favorite: "Ajouter aux favoris",
    wordlimit_reached: "Limite de mots atteinte",
    wordlimit_increase: "Augmenter la limite de mots",
    contact: "Ouvrir le contact",
    history: "Historique",
    show_history: "Afficher l'historique",
    inprint: "Mentions légales",
    dataregulation: "Protection des données",
    tos: "CGV",
    month: "Mois",
    cancel_anytime: "Annulation à tout moment",
    try_now: "essayer gratuitement",
    book_again: "Réserver à nouveau",
    book: "Réserver maintenant",
    upgrade_to: "Passer à {product}",
    copied_link: "Lien copié",
    subject: "Sujet",
    generated_by_hermann: "Généré par Hermann en 3 secondes.",
    use_template: "Utiliser le modèle",
    show_mode: "Afficher plus",
    upload: "télécharger",
    optional: "facultatif",
    my_documents: "Mes Documents",
    my_sources: "Mes Sources",
    add_source: "Ajouter une source",
    select_source: "Sélectionner une source",
    page: "Page {page}",
    unknown_author: "Auteur inconnu",
    checker_results: "Résultats du détecteur IA",
    human: "humain",
    citation_style: "Style de citation",
    started_at: "commencé le",
    ends_at: "se termine le",
    cancel_subscription: "annuler l'abonnement"
  },
  blog: {
    written_by: "Écrit par",
    read_article: "Lire l'article",
  },
  source: {
    select: {
      empty: {
        headline: "Il n'y a encore rien ici.",
        hint: "Vous pouvez marquer des sources dans 'Trouver des sources' et les utiliser dans différents outils."
      },
    },
    table: {
      name: "Nom",
      type: "Type",
      created_at: "Créé le",
    },
    type: {
      document: "Document",
      article: "Article",
      paper: "Papier",
    },
    hint: {
      not_usable: "Les sources non encore traitées ne sont pas prises en compte lors de l'écriture.",
    },
    processing: "en cours de traitement...",
    recent: "utilisé récemment",
    bookmark: "marquer",
    bookmarked: "marqué"
  },
  placeholders: {
    url: "https://en.wikipedia.org/wiki/Radiocarbon_dating"
  },
  groups: {
    research: "Research",
    essay: "Essay",
    misc_tools: "outils divers",
  },
  flows: {
    summarize: {
      headline: "Résumer",
      description: "Que voulez-vous résumer?"
    },
    titles: {
      summarize_document: "Document",
      summarize_text: "Texte",
      summarize_article: "Article",
      summarize_source: "Source",
    },
    prefill: {
      summarize_document: "Résumer le document.",
    }
  },
  messages: {
    get_more_words: "Obtenir plus de mots maintenant",
    contact_success: "Merci pour votre message!",
    error: "Quelque chose ne s'est pas déroulé correctement. Veuillez réessayer ultérieurement.",
  },
  validation: {
    required: "{_field_} est un champ obligatoire",
    min: "{_field_} doit contenir au moins {length} caractères",
    max: "{_field_} ne doit pas dépasser {length} caractères",
    maxarray: "{_field_} a trop d'éléments",
  },
  domains: {
    openai: {
      status: {
        unreliable: 'Nous avons actuellement une charge serveur accrue. Il peut y avoir des pannes. Si quelque chose ne fonctionne pas, veuillez réessayer plus tard.',
        working_on_fix: 'Nous travaillons actuellement sur une solution. Veuillez réessayer plus tard.',
      }
    },
    ai_checker: {
      hint: 'Les détecteurs d\'IA sont des outils qui vérifient les textes pour leur contenu IA. En cliquant sur l\'une des cartes, vous accédez à l\'outil correspondant.',
    },
    cookie: {
      text1: 'Hesse.ai utilise des cookies et des données pour améliorer notre site web et adapter les contenus à vos besoins. En cliquant sur le bouton "accepter", vous acceptez leur utilisation. Vous trouverez de plus amples informations dans notre',
      user_behaviour_text: "Ces cookies nous permettent d'analyser anonymement le comportement des utilisateurs sur Hesse.ai afin d'optimiser l'expérience utilisateur.",
      user_behaviour: "Comportement des utilisateurs (hotjar)",
      user_behaviour2_text: "Ces cookies nous permettent d'analyser anonymement la navigation et l'origine des utilisateurs sur Hesse.ai.",
      user_behaviour2: "Comportement des utilisateurs (Google Analytics)",
      technical: "Cookies techniquement nécessaires",
      technical_text: "Ces cookies sont nécessaires pour assurer une utilisation sécurisée et fiable.",
      consent: "Accepter",
      save: "Appliquer",
      decline: "Refuser",
    },
    write: {
      confirm_delete_paragraph: "Supprimer réellement le paragraphe ?",
      save_changes: "Enregistrer les modifications",
      keep_writing: "Continuer à écrire",
    },
    report: {
      title: "Signaler réellement cette réponse ?",
      reason: "Motif de signalement",
      description: "Signalez les résultats contenant un contenu critique ou illégal.",
      result_good: "Résultat utile",
      result_bad: "Résultat non utile",
    },
    pricing: {
      monthly_wordlimit: "{limit} mots par mois",
      increase_limit: "Augmenter la limite",
      wordlimit_reached: "Vous avez atteint la limite de mots",
      wordlimit_reached_description: "Le contingent pour ce mois est épuisé. Si vous souhaitez continuer, vous devez augmenter votre limite.",
      wordlimit_nearly_reached: "Vous avez presque épuisé vos mots.",
      get_more_words: "Obtenir plus de mots",
      get_more_words_description: "Les bons textes nécessitent beaucoup de puissance de calcul. Cela coûte cher.<br /> Mais nous avons rendu nos offres aussi avantageuses que possible.",
      error_title: "La réservation n'a malheureusement pas fonctionné",
      error_description: "Contactez-nous - Nous vous aiderons immédiatement.",
      text1: "Que représentent 10 000 mots ?",
      text2: "C'est ce que représentent 10 000 mots",
      referral_title: "Vous n'arrivez pas encore à vous décider ?",
      referral_title2: "Envie de plus de mots ?",
      referral_description: "Partagez votre lien avec des amis. Vous obtiendrez 1 000 mots gratuits pour chaque nouvelle inscription via votre lien.",
    },
    survey: {
      title: "Comment évaluez-vous Hesse.ai ?",
      suggestions: "Que changeriez-vous ?",
      success_title: "Merci beaucoup pour vos commentaires !",
      success_text: "Vous nous aidez à améliorer continuellement Hesse.ai",
      abort: "ne pas évaluer",
    },
    cards_hint: {
      title: "Les cartes flash sont maintenant disponibles",
      subtitle: "Créer automatiquement des <strong>cartes flash</strong> à partir de votre <strong>cours</strong> ?",
      cta: "Créer des cartes flash maintenant",
    },
    share_modal: {
      headline: "Partagez Hesse - obtenez des mots gratuits",
    },
    login_page: {
      loading: "Connexion en cours...",
      error: "La connexion n'a pas fonctionné",
      delayed: "La connexion prend plus de temps que d'habitude.<br /> Veuillez vérifier votre connexion Internet et réessayer si nécessaire.",
      try_again: "Se connecter à nouveau",
    },
    index_page: {
      subtitle: 'Hermann Hesse a un jour dit : "Un bon écrivain est celui qui sait bien écrire." Cela s\'applique également à notre programme. Nous pouvons vous aider à amener vos textes au niveau supérieur.',
      favourites: "Vos favoris",
    },
    sidebar: {
      create_own_application: "Créer une application personnelle",
      hesse_cards: "Hesse Cards 🃏",
      all_applications: "Toutes les applications",
      favourites: "Mes favoris",
    },
    profile: {
      title: "Paramètres du profil",
      limit: "Limite",
      used_tokens: "Tokens utilisés",
      get_more_words: "Obtenir plus de mots maintenant",
      no_subscription: "Vous n'avez pas encore d'abonnement.",
      upgrade_now: "Passer à un abonnement",
      manage_subscription: "Gérer l'abonnement",
      delete_account: "Supprimer le compte",
      deleted: "Votre compte a été supprimé. Lorsque vous vous connecterez, un nouveau compte sera créé.",
    },
    contact: {
      title: "Contact",
      subtitle: "Contactez-nous",
    },
    error_page: {
      title404: "404 - Page non trouvée",
      title: "Une erreur s'est produite",
      text: "Aidez-nous à améliorer Hesse.ai.<br /> Décrivez brièvement ce que vous avez fait juste avant que cette page apparaisse.",
      cta: "Vers la page d'accueil",
    },
    success_page: {
      loading: "Chargement...",
      booking_success: "Votre réservation a été effectuée avec succès !",
      booked_product: "Vous avez réservé {productName}",
      start_now: "Commencer maintenant",
    }
  },
  typewriter: {
    writeEssay: [
      // 'Recherche dans la base de données source...',
      // 'Recherche de sources avec Google...',
      'Génération de textes...',
      'Chiffrement de textes...',
      'Vérification du plagiat...',
      'Exécution des outils de vérification IA...',
      'Textes ne sont plus traçables ✔'
    ],
    writeOutline: [
      'Génération de textes...',
      'Chiffrement de textes...',
      'Vérification du plagiat...',
      'Exécution des outils de vérification IA...',
      'Textes ne sont plus traçables ✔'
    ],
    writeIntroduction: [
      'Génération de textes...',
      'Chiffrement de textes...',
      'Vérification du plagiat...',
      'Exécution des outils de vérification IA...',
      'Textes ne sont plus traçables ✔'
    ],
  },
};
