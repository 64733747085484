import Vue from 'vue'
import VueGtag, { bootstrap } from 'vue-gtag'

let isEnabled = false
export default ({ app, $consent, $config }, inject) => {
  if (!$config.isProduction) return
  Vue.use(
    VueGtag,
    {
      enabled: $config.isProduction,
      bootstrap: false,
      config: {
        id: 'G-KCBEFTF2JW',
      },
    },
    app.router
  )
  function addScript() {
    if (isEnabled) return
    isEnabled = true
    console.info('Enabling Google Analytics')
    bootstrap()
  }
  if ($consent.cookies.gtag) {
    addScript()
  } else {
    if (!app.mixins) app.mixins = []
    app.mixins.push({
      created() {
        if (this.$consent.cookies.gtag) {
          addScript()
        }
      },
      watch: {
        '$consent.cookies.gtag'(newVal) {
          if (newVal) {
            addScript()
          }
        },
      },
    })
  }

  inject('gtag', {
    enable: addScript,
    isEnabled: Vue.observable(isEnabled),
  })
}
