export default function ({$auth, $segment, app}) {
  if ($auth) {
    app.mixins.push({
      data() {
        return {
          currentUser: null,
        }
      },
      methods: {
        identifyUser(user){
          this.currentUser = user
          $segment.identify(user.email, {
            email: user.email,
            name: user.name,
            subscription: user?.subscription?.slug ?? 'free',
          })
          if (window?.tolt_referral) {
            window.tolt.signup(user.email)
          }
        }
      },
      mounted() {
        this.$nextTick(() => {
          if (this.$auth?.user) {
            this.identifyUser(this.$auth.user)
          } else {
            this.$store.watch((state)=> {
                  if (state.auth.user && !this.currentUser) {
                    this.identifyUser(state.auth.user)
                  }
                },
                (_)=>{
                },
                {
                  deep:true
                }
            );
          }
        })
      },
    })
  }
}
