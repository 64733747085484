import locale77427e78 from '../../lang/en.js'
import locale77304fa0 from '../../lang/de.js'
import locale77526775 from '../../lang/fr.js'
import locale7744c453 from '../../lang/es.js'
import locale777d96b6 from '../../lang/it.js'
import locale77c06943 from '../../lang/nl.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en","file":"en.js","flag":"🇺🇸"},{"code":"de","iso":"de","file":"de.js","flag":"🇩🇪","isCatchallLocale":true},{"code":"fr","iso":"fr","file":"fr.js","flag":"🇫🇷"},{"code":"es","iso":"es","file":"es.js","flag":"🇪🇸"},{"code":"it","iso":"it","file":"it.js","flag":"🇮🇹"},{"code":"nl","iso":"nl","file":"nl.js","flag":"🇳🇱"}],
  defaultLocale: "de",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: false,
  langDir: "lang",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":".hesse.ai","cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "https://essay.hesse.ai",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","iso":"en","file":"en.js","flag":"🇺🇸"},{"code":"de","iso":"de","file":"de.js","flag":"🇩🇪","isCatchallLocale":true},{"code":"fr","iso":"fr","file":"fr.js","flag":"🇫🇷"},{"code":"es","iso":"es","file":"es.js","flag":"🇪🇸"},{"code":"it","iso":"it","file":"it.js","flag":"🇮🇹"},{"code":"nl","iso":"nl","file":"nl.js","flag":"🇳🇱"}],
  localeCodes: ["en","de","fr","es","it","nl"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: ".",
  5: "j",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: ".",
  12: "/",
  13: "l",
  14: "a",
  15: "n",
  16: "g",
  17: "/",
  18: "e",
  19: "n",
  20: ".",
  21: "j",
  22: "s",
  23: "\"",
  24: ",",
  25: "\"",
  26: "d",
  27: "e",
  28: ".",
  29: "j",
  30: "s",
  31: "\"",
  32: ":",
  33: "\"",
  34: ".",
  35: ".",
  36: "/",
  37: "l",
  38: "a",
  39: "n",
  40: "g",
  41: "/",
  42: "d",
  43: "e",
  44: ".",
  45: "j",
  46: "s",
  47: "\"",
  48: ",",
  49: "\"",
  50: "f",
  51: "r",
  52: ".",
  53: "j",
  54: "s",
  55: "\"",
  56: ":",
  57: "\"",
  58: ".",
  59: ".",
  60: "/",
  61: "l",
  62: "a",
  63: "n",
  64: "g",
  65: "/",
  66: "f",
  67: "r",
  68: ".",
  69: "j",
  70: "s",
  71: "\"",
  72: ",",
  73: "\"",
  74: "e",
  75: "s",
  76: ".",
  77: "j",
  78: "s",
  79: "\"",
  80: ":",
  81: "\"",
  82: ".",
  83: ".",
  84: "/",
  85: "l",
  86: "a",
  87: "n",
  88: "g",
  89: "/",
  90: "e",
  91: "s",
  92: ".",
  93: "j",
  94: "s",
  95: "\"",
  96: ",",
  97: "\"",
  98: "i",
  99: "t",
  100: ".",
  101: "j",
  102: "s",
  103: "\"",
  104: ":",
  105: "\"",
  106: ".",
  107: ".",
  108: "/",
  109: "l",
  110: "a",
  111: "n",
  112: "g",
  113: "/",
  114: "i",
  115: "t",
  116: ".",
  117: "j",
  118: "s",
  119: "\"",
  120: ",",
  121: "\"",
  122: "n",
  123: "l",
  124: ".",
  125: "j",
  126: "s",
  127: "\"",
  128: ":",
  129: "\"",
  130: ".",
  131: ".",
  132: "/",
  133: "l",
  134: "a",
  135: "n",
  136: "g",
  137: "/",
  138: "n",
  139: "l",
  140: ".",
  141: "j",
  142: "s",
  143: "\"",
  144: "}",
}

export const localeMessages = {
  'en.js': () => Promise.resolve(locale77427e78),
  'de.js': () => Promise.resolve(locale77304fa0),
  'fr.js': () => Promise.resolve(locale77526775),
  'es.js': () => Promise.resolve(locale7744c453),
  'it.js': () => Promise.resolve(locale777d96b6),
  'nl.js': () => Promise.resolve(locale77c06943),
}
